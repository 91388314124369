@charset 'UTF-8';
.c-btn {
  display: block;
  max-width: 320px;
  height: 60px;
  border: 1px solid #101010;
  position: relative;
  @include font-size(15);
  @include noto-serif;
  color: #101010;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform_c(translate(-50%,-50%));
  }
  @include SP {
    border: 1px solid #707070;
    position: relative;
    height: 52px;
    @include font-size(13);
    &:after {
      @include absolute(6px,6px,50%,20px,'','');
      display: inline-block;
      transition: all 0.6s;
      border: solid #999;
      border-width: 0 1px 1px 0;
      @include transform_c(rotate(-45deg) translateY(-50%)); 
    }
  }
}
.imgbg, .lazy {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}


.scandinavia{
  &__head{
    @include noto-serif;
    @include font-size(38);
    text-align: center;
    margin: 85px auto 28px;
    letter-spacing: 0.2em;
    line-height: 1.4;
    @include SP {
      margin: 40px auto 15px;
      @include font-size(26);
    }
  }
  &__headsub{
    @include font-size(14);
    text-align: center;
    margin-bottom: 70px;
    letter-spacing: 0.08em;
    font-weight: 300;
    @include SP {
      @include font-size(11);
      margin-bottom: 35px;
    }
  }
  &__inner {
    .listttl {
      &__img{

      }
      &__txt{

      }
    }
  }
  &__list{
    @include PC {
      @include flexbox;
      @include flex-wrap;
      @include justify-content(space-between);
    }
    li{
      transition: 0.3s;
      @include PC {
        width: calc(50% - 20px);
        margin-bottom: 65px;
      }
      @include SP {
        margin-bottom: 43px;
      }
      a {
        display: block;
      }
    }
    .listhead{
      @include flexbox;
      @include flex-wrap;
      @include justify-content(space-between);
      letter-spacing: 0.1em;
      margin-bottom: 27px;
      position: relative;
      pointer-events: none;
      z-index: 1;
      a{
        pointer-events: all;
      }
      @include SP {
        margin-bottom: 17px;
      }
      &__img{
        width: 60px;
        height: 60px;
        position: relative;
        img{
          min-width: 100%;
          min-height: 100%;
          @include border-radius(50%);
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform_c(translate(-50%,-50%));
        }
        @include SP {
          width: 44px;
          height: 44px;
        }
      }
      &__ttl{
        @include eb-garamond;
        @include font-size(24);
        text-align: left;
        line-height: 1.3;
        letter-spacing: 0.1em;
        width: calc(100% - 180px);
        @include SP {
          width: calc(100% - 144px);
          line-height: 1;
        }
        .ttl{
          i {
            font-style: inherit;
            &:after{
              content: ", ";
            }
            &:last-child{
              &:after{
                display: none;
              }
            }
          }
        }
        span a{
          color: #4E7A91;
          @include font-size(12);
          display: block;
          @include noto-sans;
          margin-top: 5px;
          letter-spacing: 0.08em;
          @include multiLineEllipsis(1);
          line-height: 1.2;
        }
        @include SP {
          @include font-size(18);
          span a{
            @include font-size(11);
            margin-top: 5px;
          }
        }
      }
      > a{
        align-self: flex-end;
      }
      &__date{
        width: 70px;
        color: #696969;
        @include font-size(10);
        text-align: right;
        align-self: flex-end;
        letter-spacing: 0.08em;
        @include noto-sans;
        @include SP {
          @include font-size(8);
        }
      }
    }
    .listimg {
      
    }
    .listttl {
      @include font-size(14);
      margin-top: 15px;
      letter-spacing: 0.06em;
      color: #27333c;
      @include multiLineEllipsis(1);
      @include SP {
        margin-top: 10px;
        @include font-size(12);
      }
    }
    a{ 
      display: block;
    }
  }
  .c-btn {
    margin: auto;
  } 
}
.headtopcm{
  @include eb-garamond;
  @include font-size(52);
  letter-spacing: 0.2em;
  margin-bottom: 60px;
  font-weight: 500;
  h2{
    @include font-size(15);
    @include noto-serif;
    padding-left: 30px;
    letter-spacing: 0.3em;
    display: inline;
    top: -5px;
    position: relative;
  }
  @include SP {
    @include font-size(30);
    letter-spacing: 0.2em;
    margin-bottom: 30px;
    h2{
      @include font-size(12);
      display: block;
      padding-left: 0;
      letter-spacing: 0.3em;
      margin-top: 10px;
    }
  }
}
.finepicup {
  background: #4e7a91;
  padding: 100px 0 35px;
  @include SP {
    padding: 40px 0;
  }
  .headtopcm{
    @include font-size(46);
    color: #fff;
    @include SP {
      @include font-size(32);
      margin-bottom: 35px;
    }
  }
  .listttl{
    color: #fff;
  }
  @include SP {
    .scandinavia__list {
      @include flexbox;
      @include flex-wrap;
      @include justify-content(space-between);
      li{
        width: calc(50% - 4px);
      }
    }
  } 
}

// -------------------------------------------------------------------
.pageTitle {
  color: #1c1c1c;
  text-align: center;
  position: relative;
  overflow: hidden;
  @include SP{
    &__inner{
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
  &__en {
    text-transform: uppercase;
    @include eb-garamond();
    @include PC {
      @include font(52, '', 0.2em, 500);
      margin: 0 0 4px;
    }
    @include SP {
      @include font(34, '', 0.2em, 500);
      text-align: left;
    }
  }
  &__jp {
    display: block;
    @include noto-serif();
    @include PC {
      @include font(14, 24px, 0.14em, '');
    }
    @include SP {
      @include font(13, 42px, 0.14em, '');
      margin: 0 0 0 9px;
    }
  }
}
// -------------------------------------------------------------------
.img_thumb {
  position: relative;
  width: 100%;
  z-index: 1;
  transition: 0.3s ease;
  span {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    left: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
// -------------------------------------------------------------------
.contcms{
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  table, table th, table td{
    border: thin;
  }
  .box{
    border-radius: 5px;
    background: transparent;
    border: 1px solid #c4c4c4;
    padding: 38px 36px 38px 42px;
    box-sizing: border-box;
    @include flexbox();
    @include align-items(flex-start);
    @include PC{
      width: 799px;
      height: 214px;
      margin: 57px auto 39px;
    }
    @include SP{
      margin: 27px auto 26px;
      padding: 20px 20px 15px 20px;
    }
    &__left{
      @include PC{
        width: 160px;
      }
      @include SP{
        width: 122px;
      }
    }
    &__right{
      width: calc(100% - 160px);
      padding: 0 0 0 30px;
      @include SP{
        width: calc(100% - 122px);
        padding: 0 0 0 18px;
      }
    }
    &__title{
      text-align: left;
      color: #101010;
      @include PC{
        @include font(16,24px,0.08em, normal);
        margin: -3px 0 12px 0;
      }
      @include SP{
        @include font(14,22px,0.08em, normal);
        margin: 0 0 3px 0;
      }
    }
    &__text{
      text-align: left;
      color: #101010;
      @include PC{
        margin: 0 0 11px;
        @include font(14,24px,0.08em, 300);
      }
      @include SP{
        @include font(12,22px,0.08em, 300);
        @include multiLineEllipsis(4);
        margin-bottom: 0;
      }
    }
    &__link{
      height: 22px;
      background: transparent;
      text-align: left;
      color: #696969;
      padding: 0 10px 0 0;
      background: url(../img/common/icon/ico-arrow.svg) no-repeat center right;
      background-size: 10px 10px;
      display: block;
      position: relative;
      &::after{
        content: "";
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 82%;
        height: 1px;
        background: #696969;
      }
      @include PC{
        width: 100px;
        margin: 0 -10px 0 auto;
        @include font(15,22px,0.08em, normal);
      }
      @include IE{
        margin-right: -8px;
      }
      @include SP{
        width: 65px;
        margin: 0 0 0 auto;
        @include font(10,22px,0.08em, normal);
        &::after{
          bottom: 5px;
        }
      }
    }
  }
  blockquote{
    border-radius: 5px;
    background: transparent;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    position: relative;
    @include PC{
      padding: 25px 26px 22px 30px;
      margin-bottom: 50px;
    }
    @include SP{
      padding: 25px 20px 16px 20px;
      margin: 41px 0 39px;
    }
    &::before{
      content: "";
      position: absolute;
      background: url(../img/common/icon/ico_cmm.svg) no-repeat center right;
      background-size: 30px 25px;
      width: 30px;
      height: 25px;
      @include PC{
        top: -9px;
        left: 8px;
      }
      @include IE{
        width: 32px;
      }
      @include SP{
        top: -10px;
        left: 13px;
      }
    }
    p{
      text-align: left;
      color: #222;
      @include font(13,22px,0.08em, 300);
      margin-bottom: 9px;
      &:last-child{
        margin-bottom: 0;
      }
      small{
        text-align: right;
        margin-bottom: 0;
        display: inline-block;
        @include PC{
          @include font-size(12);
        }
        @include SP{
          @include font-size(10);
        }
      }
      a{
        color: #696969;
        text-decoration: underline;
        //position: relative;
        // &::after{
        //   content: "";
        //   position: absolute;
        //   bottom: 2px;
        //   left: 0;
        //   width: 100%;
        //   height: 1px;
        //   background: #696969;
        // }
      }
    }
  }
  ul{
    @include PC{
      margin-bottom: 42px;
    }
    @include SP{
      margin-bottom: 13px;
    }
    li{
      text-align: left;
      color: #101010;
      position: relative;
      &::before{
        content: "";
        position: absolute;
        background: #000;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        @include PC {
          top: 10px;
          left: 2px;
        }
        @include IE {
          top: 8px;
        }
        @include SP {
          top: 8px;
          left: 4px;
        }
      }
      @include PC {
        @include font(14, 22px, 0.08em, 300);
        padding: 0 0 5px 15px;
      }
      @include SP {
        font-size: 12px;
        padding: 0 0 0 15px;
        margin-bottom: 7px;
      }
    }
  }
  h2{
    @include noto-serif ();
    @include font(28,44px,0.14em, normal);
    padding-bottom: 20px;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
    margin-bottom: 38px;
    @include SP{
      font-size: 22px;
      line-height: 34px;
      padding-bottom: 18px;
      margin-bottom: 22px;
    }
  }
  h3{
    border-left: 3px solid #c4c4c4;
    box-sizing: border-box;
    padding-left: 15px;
    margin-bottom: 34px;
    @include ffYM();
    @include font(18,28px,0.14em, normal);
    @include IE{
      padding-top: 6px;
    }
    @include SP{
      margin-bottom: 23px;
    }
  }
  h4{
    @include ffYM();
    @include font(16,26px,0.14em, 500);
    position: relative;
    padding-left: 17px;
    margin-bottom: 14px;
    &::before{
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      background: #474a4d;
      width: 10px;
      height: 1px;
      @include IE{
        top: 9px;
      }
    }
  }
  p{
    margin-bottom: 18px;
    @include font(14,26px,0.08em, 300);
    @include SP{
      @include font-size(12);
      line-height: 22px;
      margin-bottom: 19px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    strong{
      color: #4E7A91;
      position: relative;
      background: linear-gradient(to bottom, transparent 65%, #58798f33 50%);
      @include PC{
        @include font(14,26px,0.08em, bold);
      }
      @include SP{
        @include font(12,22px,0.08em, normal);
      }
    }
    a{
      text-decoration: underline;
    }
  }
  em{
    background: #f2f2f2;
    text-align: left;
    display: block;
    font-style: normal;
    @include font(14,26px,0.08em, 300);
    color: #101010;
    @include PC{
      padding: 35px 49px 35px;
      margin: 20px 0;
    }
    @include SP {
      margin: 6px 0 0;
      padding: 19px 22px 23px;
      font-size: 12px;
      line-height: 22px;
    }
  }
}
// -------------------------------------------------------------------
.cmn_pagenavi {
  .count_pages {
    display: none;
  }
  .wp-pagenavi {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include  eb-garamond();
    @include PC {
      padding: 32px 0;
    }
    .pages {
      display: none;
    }
    a,
    span {
      @include font(18, '', -0.05em, 500);
      width: 32px;
      height: 32px;
      display: inline-block;
      border-radius: 50%;
      color: #222;
      margin: 0 2px;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
    }
    a {
      background: #fff;
      &.previouspostslink,
      &.nextpostslink,{
        border: none;
        position: relative;
        top: 0;
        color: transparent;
        font-size: 0;
        background: #edf0f3;
      }
      &.previouspostslink,
      &.first {
        &::after{
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background: url(../img/common/icon/ico_prev.svg) no-repeat center center;
          background-size: 9px 9px;
          width: 9px;
          height: 9px;
        }
        @include PC {
          left: -20px;
        }
        @include SP {
          left: 6px;
          position: absolute;
        }
      }
      &.nextpostslink,
      &.last {
        &::after{
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
        transform: translate(-50%,-50%);
          background: url(../img/common/icon/ico_next.svg) no-repeat center center;
          background-size: 9px 9px;
          width: 9px;
          height: 9px;
        }
        @include PC {
          right: -20px;
        }
        @include SP {
          right: 6px;
          position: absolute;
        }
      }
      &.last,
      &.first{
        display: none;
      }
      .nextpostslink,
      .previouspostslink {
        font-size: 0 !important;
        line-height: 0 !important;
        top: 0 !important;
      }
      .first,
      .last {
        display: none !important;
      }
      @include PC {
        &.page {
          &:hover {
            color: #fff;
            background: #222;
            opacity: 1 !important;
          }
        }
      }
    }
    span {
      &.current {
        color: #fff;
        background: #222;
        border-color: #222;
      }
      &.extend {
        border: none;
        line-height: 25px;
      }
    }
  }
}
// -------------------------------------------------------------------
.other{
  @include flexbox;
  @include flex-wrap;
  @include justify-content(space-between);
  margin-bottom: 52px;
  @include SP{
    margin-top: 40px;
    margin-bottom: 20px;
  }
  @include PC {
    &:after{
      content: '';
      width: calc(33.33333333% - 26px);
      display: block;
    }
  }
  &__item{
    width: 100%;
    margin: 0 0 64px;
    position: relative;
    transition: 0.3s;
    &.hover{
      @include HOVER {
        &:hover{
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
    @include PC {
      width: calc(33.33333333% - 26px);
    }
    @include SP {
      width: calc(50%% - 5px);
      margin-bottom: 36px;
    }
    @include IP5{
      max-width: 48%;
    }
    a{
      display: block;
    }
    .img_thumb{
      @include aspect-ratio(380px, 253px);
      @include PC{
        margin: 0 0 18px;
      }
      @include SP{
        margin: 0 0 10px;
      }
    }
    .ttl{
      color: #1c1c1c;
      @include PC{
        @include font(14,22px,0.06em, normal);
        margin-bottom: 9px;
      }
      @include SP{
        @include font(12,20px,0.06em, normal);
        margin-bottom: 0;
      }
    }
    .txt{
      @include font(11,"",0.08em, normal);
      color: #696969;
      @include PC{
        margin-bottom: 13px;
      }
      @include SP{
        @include font-size(10);
        margin-bottom: 2px;
        margin-top: 6px;
        color: #1c1c1c;
      }
    }
    .link{
      display: inline-block;
      color: #1c1c1c;
      @include font(14,"",0.08em, 500);
      @include eb-garamond();
      position: relative;
      background: url(../img/common/icon/ico-arrow.svg) no-repeat center right;
      background-size: 10px 10px;
      padding: 0 12px 2px 2px;
      opacity: 1;
      @include SP{
        @include font-size(10);
        background-size: 6px 6px;
        background-position: top 4.5px right;
      }
      &::before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #1c1c1c;
      } 
    }
    .date{
      color: #4e7a91;
      @include eb-garamond();
      @include font(13,"",0.08em, 500);
      margin: 0 0 5px;
    }
    .ctn{
      @include PC{
        width: calc(100% - 60.38%);
        padding: 0 0 0 30px;
      }
      @include SP{
        width: calc(100% - 174px);
        padding: 0 0 0 16px;
      }
    }
  }
  &--type1{
    @include SP {
      width: calc(100% - 10px);
      margin: auto;
    }
    .other__item{
      @include PC{
        width: calc(25% - 25px);
        margin-left: 25px;
        &:first-child{
          margin-left: 0;
        }
      }
      @include SP{
        width: calc(50% - 4px);
      }
    }
    &:after{
      display: block;
      width: calc(25% - 25px);
      content: '';
    }
    .img_thumb{
      @include aspect-ratio(280px, 186px);
      @include PC{
        margin: 0 0 24px;
      }
      @include SP {
        margin: 0 0 6px;
      }
    }
    .ttl{
      color: #1c1c1c;
      @include PC{
        @include font(14,24px,0.1em, normal);
        margin-bottom: 9px;
      }
      @include SP{
        @include font(12,20px,0.02em, normal);
      }
    }
    @include PC {
      &:after{
         width: calc(25% - 25px);
         content: '';
         display: block;
      }
    }
  }
  &--type2{
    @include PC {
      @include flexbox;
      @include flex-wrap;
      @include justify-content(space-between);
    }
    .other__item{
      @include PC{
        width: 48.183%;
        margin-bottom: 61px;
        .ttl{
          margin-bottom: 21px;
        }
      }
      @include SP{
        width: 49%;
        margin-bottom: 42px;
      }
    }
    .img_thumb{
      @include aspect-ratio(3px, 2px);
      @include PC{
        margin: 0 0 30px;
      }
      @include SP{
        margin: 0 0 6px;
      }
    }
    .ttl{
      color: #1c1c1c;
      @include PC{
        @include font(14,22px,0.06em, normal);
        margin-bottom: 9px;
      }
      @include SP{
        @include font(12,20px,0.06em, normal);
      }
    }
    .link{
      @include PC{
        font-size: 17px;
        @include ffYM();
        letter-spacing: 0.04em;
        color: #1c1c1c;
        font-weight: 600;
        padding-right: 20px;
        padding-bottom: 8px;
        background-position: top 10px right;
      }
      @include SP{
        text-transform: uppercase;
        margin: 11px 0 0;
      }
    }
  }
  &--type3{
    @include PC {
      @include flexbox;
      @include flex-wrap;
      @include justify-content(space-between);
    }
    .other__item{
      display: flex;
      align-items: flex-start;
      @include PC{
        width: 48.183%;
        margin-bottom: 36px;
      }
      @include SP{
        max-width: 100%;
        margin-bottom: 3px;
      }
    }
    .img_thumb{
      @include PC{
        @include aspect-ratio(320px, 213px);
        margin: 0 ;
        width: 60.38%;
      }
      @include SP {
        margin: 5px 0 6px;
        @include aspect-ratio(174px, 116px);
        max-width: 174px;
      }
    }
    .ttl{
      color: #1c1c1c;
      @include multiLineEllipsis(2);
      @include PC{
        @include font(14,22px,0.08em, normal);
        margin-bottom: 9px;
      }
      @include SP{
        @include font(12,18px,0.08em, normal);
      }
    }
    .txt{
      @include PC{
        @include multiLineEllipsis(7);
      }
      @include SP{
        margin-top: 7px;
      }
    }
    .date{
      @include SP{
        letter-spacing: .04em;
        margin-bottom: 2px;
      }
    }
    .link{
      background: none;
      &::before{
        display: none;
      }
    }
  }
  &.margin1{
    @include PC{
      margin-bottom: 64px;
    } 
    @include SP{
      margin-bottom: 28px;
    }
  }
  &.margin2{
    @include PC{
      margin-bottom: 83px;
    }
    @include SP{
      margin-bottom: 41px;
    }
  }
  &.margin3{
    @include PC{
      margin-bottom: 59px;
    }
    @include SP{
      margin-bottom: 8px;
    }
  }
  &.margin4{
    @include PC{
      margin-bottom: 20px;
    }
    @include SP{
      margin-bottom: 37px;
    }
  }
}
// -------------------------------------------------------------------
.btn-link{
  background: transparent;
  border: 1px solid #b5b5b5;
  text-align: left;
  color: #1c1c1c;
  position: relative;
  width: 320px;
  height: 52px;
  @include ffYG();
  @include font(13,'',0.2em, 500);
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include PC{
    margin: 25px auto;
  }
  @include SP{
    margin: 11px auto;
  }
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(-90deg);
    background: url(../img/common/icon/ico_select.svg) no-repeat center center;
    background-size: 9px 9px;
    width: 9px;
    height: 9px;
  }
}
// -------------------------------------------------------------------
.ttlHead{
  text-align: center;
  margin: 0 0 44px;
  @include SP{
    margin-bottom: 33px;
  }
  &--mb1{
    @include PC{
      margin-bottom: 39px;
    }
    @include SP{
      margin-bottom: 20px;
    }
  }
  .heading{
    color: #101010;
    @include font(30,"",0.18em, 500);
    @include eb-garamond();
    text-transform: uppercase;
    @include PC{
      margin-bottom: 8px;
    }
    @include SP{
      @include font-size(24);
      margin-bottom: 3px;
    }
  }
  .subheading{
    color: #101010;
    @include noto-serif();
    @include font(14,"",0.2em, normal);
    margin: 0 0 19px;
    @include SP{
      @include font-size(13);
      margin: 0 0 11px;
    }
  }
  .link{
    color: #696969;
    @include font(13,16px,0.14em, 500);
    @include eb-garamond();
    text-decoration: underline;
  }
}

.bl-hover{
  opacity: 0.5;
}