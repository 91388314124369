@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

// CUSTOM

.leftCol {
  width:70%;
  float:left;
}
.rightCol {
  width:30%;
  float:right;
}

.cmsContent {
  .linkcard {
    margin: 40px auto 40px;
    .lkc-this-wrap,.lkc-external-wrap,.lkc-internal-wrap{
      border: none;
      max-width: none;
      background: none;
    }
    .lkc-card {
      margin: 0;
    }
    .lkc-link {
      display: block;
      padding: 35px 35px 65px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      @include SP {
        padding: 20px 20px 40px;
      }
      .lkc-thumbnail {
        max-width: 160px;
        float: none;
        position: absolute;
        left: 0;
        .lkc-thumbnail-img {
          width: 100%;
          @include SP {
            max-width: 120px;
          }
        }
        @include SP {
          max-width: 120px;
          margin-right: 10px;
        }
      }
      .lkc-content{
        margin: 0;
        height: auto;
        padding-left: 190px;
        position: relative;
        min-height: 107px;
        @include SP {
          padding-left: 140px;
          min-height: 80px;
        }
      }
      .lkc-title-text {
        @include font-size(16);
        letter-spacing: 0.08em;
        font-weight: 500;
        @include multiLineEllipsis(1);
        width: auto;
        margin-top: -5px;
        margin-bottom: 10px;
        line-height: 1.6;
        @include SP {
          margin-bottom: 5px;
          @include font-size(14);
          @include multiLineEllipsis(2);
          width: auto;
        }
      }
      .lkc-excerpt {
        @include font-size(14);
        font-weight: 300;
        line-height: 1.6;
        @include multiLineEllipsis(3);
        width: auto;
        @include SP {
          @include font-size(12);
          @include multiLineEllipsis(4);
          width: auto;
        }
      }
      position: relative;
      &:before {
          text-decoration: underline;
          position: absolute;
          content: '続きを読む';
          right: 50px;
          bottom: 35px;
          color: #696969;
          @include SP {
            @include font-size(10);
            right: 32px;
            bottom: 20px;
          }
      }    
      &:after {
            content: "";
            border: solid #696969;
            border-width: 0 1px 1px 0;
            display: block;
            position: absolute;
            right: 35px;
            bottom: 42px;
            padding: 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            @include SP {
              right: 20px;
              bottom: 24px;
            }
      }
    }
  }
}

.pagingDt {
  position:relative;
  width:80%;
  margin:95px auto;
  font-size:14px;
}
.pagingDt a {
  text-decoration:underline;
}
.pagingDt .prev {
  background:url(../../img/blog/ico_prev.png) no-repeat left center;
  width:145px;
  float:left;
  padding-left:20px;
}
.pagingDt .next {
  background:url(../../img/blog/ico_next.png) no-repeat right center;
  width:145px;
  float:right;
  padding-right:20px;
}
.pagingDt .list {
  position:absolute;
  left:50%;
  top:50%;
  margin-left:-54px;
  margin-top:-9px;
}
