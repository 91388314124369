@charset 'UTF-8';
.footer {
	background: #1c1c1c;
	padding: 100px 0 40px;
	color: #fff;
	@include SP {
		padding: 38px 0 20px;
	}
	&__inner{

	}
	&__logo{
		img{
			width: 380px;
			@include SP {
				width: 280px;
			}
		}
	}
	&__txt{
		@include font-size(14);
		margin-top: 25px;
		line-height: 1.6;
		@include SP {
			@include font-size(12);
			margin-top: 20px;
			line-height: 1.92;
		}
		a {
			text-decoration: underline;
			color: #fff;
		}
	}
	&__txtsm{
		@include font-size(14);
		margin-bottom: 40px;
		@include SP {
			@include font-size(12);
			margin-bottom: 22px;
		}
	}
	&__inst{
		@include flexbox;
		@include flex-wrap;
		@include justify-content(flex-start);
		.insttxt {
			@include eb-garamond;
			letter-spacing: 0.2em;
			@include font-size(18);
			font-weight: 500;
			position: relative;
			line-height: 1.6;
			padding-right: 50px;
			margin-top: 20px;
			margin-right: 70px;
			@include SP{
				@include font-size(12);
				margin-top: 5px;
				padding-right: 7px;
				margin-bottom: 10px;
				white-space: nowrap;
			}
			&:after{
				@include absolute(1px,56px,0,0,'','');
				background: #fff;
				@include transform_c(rotate(35deg));
				@include SP {
					height: 10px;
					top: 5px;
				}
			}
		}
	}
	&__list{
		@include flexbox;
		@include flex-wrap;
		@include justify-content(space-between);
		li {
			margin-left: 15px;
			@include SP {
				width: calc(16.6666666667% - 5px);
				margin-left: 0;
			}
			a{
				img {
					width: 90px;
					height: auto;
					@include border-radius(50%);
				}
				span{
					@include font-size(12);
					color: #fff;
					display: block;
					text-align: center;
					margin-top: 5px;
					line-height: 1.4;
				}
				@include SP {
					margin-left: 0;
					width: calc(16.6666666667% - 5px);
					span{
						@include font-size(9);
					}
				}
				@include HOVER {
				  &:hover{
				    span{
				    	opacity: 0.5;
				    }
				  }
				}
			}
		}
	}
	&__img{
		@include flexbox;
		@include flex-wrap;
		@include justify-content(space-between);
		max-width: 968px;
		margin: 50px 0;
		@include SP {
			margin: 25px 0 15px;
		}
		li {
			position: relative;
			margin-bottom: 10px;
			a {
				display: block;
				height: 76px;
				@include SP {
					height: 52px;
				}
				span {
					top: 50%;
					left: 50%;
					@include transform_c(translate(-50%,-50%));
					position: absolute;
					line-height: 1.4;
				}
				img{
					width: 100%;
					height: 100%;
				}
			}
			&:nth-child(1) {
				width: 249px;
				@include SP {
					width: calc(50% - 5px);
				}
				a{
					span{
						@include font-size(13);
						color: #1c1c1c;
						text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
						@include transform_c(translate(0%,-50%));
	                  	left: calc(60% - 10px);
	                  	top: calc(50% - 5px);
	                  	@include SP {
	                  		top: calc(50% - 2px);
	                  		@include font-size(10);
	                  	}
					}
				}	
			}
			&:nth-child(2) {
				width: 249px;
				@include SP {
					width: calc(50% - 5px);
				}
				a{
					span{
						color: #fff;
						text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
						white-space: nowrap;
						top: calc(50% - 10px);
						@include SP {
							top: calc(50% - 5px);
	                  		@include font-size(12);
	                  	}
					}
				}	
			}
			&:nth-child(3) {
				width: 400px;
				background: #fff;
				border: 1px solid #c4c4c4;
				a{
					&:after {
		            @include absolute(8px,8px,50%,20px,'','');
		            display: inline-block;
		            transition: all 0.6s;
		            border: solid #707070;
		            border-width: 0 1px 1px 0;
		            @include transform_c(rotate(-45deg) translateY(-50%)); 
		            @include SP {
		              width: 7px;
		              height: 7px;
		            }
		          }
		          span{
		          	width: 276px;
		          }
				}
				@include SP {
					width: 100%;
					a{
						span{
				          width: 215px;
				        }
					}
				}
			}
		}
	}
	&__link{
		a{
			color: #fff;
			@include font-size(14);
			letter-spacing: 0.06em;
			position: relative;
			padding-left: 15px;
			@include SP {
				@include font-size(12);
			}
			&:after {
			  @include absolute(6px,6px,50%,'','',0);
			  display: inline-block;
			  transition: all 0.6s;
			  border: solid #fff;
			  border-width: 0 1px 1px 0;
			  @include transform_c(rotate(-45deg) translateY(-50%)); 
			}
		}
	}
	&__copyright{
		@include font-size(12);
		text-align: center;
		margin-top: 100px;
		@include SP {
			margin-top: 40px;
		}
	}
	.totop {
		@include eb-garamond;
		width: 60px;
		height: 60px;
		border: 1px solid #fff;
		@include border-radius(50%);
		color: #fff;
		position: fixed;
		right: 40px;
		bottom: 40px;
		cursor: pointer;
		background: #000;
		z-index: 9999;
		span{
			top: calc(50% + 5px);
			left: 50%;
			@include transform_c(translate(-50%,-50%));
			position: absolute;
			white-space: nowrap;
			&:after {
			  @include absolute(10px,10px,-5px,'','',11px);
			  display: inline-block;
			  transition: all 0.6s;
			  border: solid #fff;
			  border-width: 0 1px 1px 0;
			  @include transform_c(rotate(-135deg)); 
			}
		}
		@include SP {
			width: 44px;
			height: 44px;
			right: 8px;
			bottom: 20px;
			span{
				@include font-size(10);
				&:after {
					width: 8px;
					height: 8px;
					left: 6px;
				}	
			}
		}
		@include HOVER {
		  &:hover{
		    opacity: 0.5;
		  }
		}
		&.off{
		    @include transform_c(translateY(200%));
		}
	}
}