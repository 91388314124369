@charset 'UTF-8';
.header{
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9991;
  @include transition_c(all 0.3s ease-out);
  @include SP {
    background: rgba(255,255,255,0.5);
    &.headerwhite{
      background: #fff;
    }
  }
  &.topUp {
    @include transform_c(translateY(-100%));
  }
  &__inner{
    height: 90px;
    @include flexbox;
    @include flex-wrap;
    @include justify-content(space-between);
    @include SP {
      height: 60px;
    }
  }
  &__hamburger{
    width: 90px;
    height: 100%;
    background: #000;
    text-align: center;
    position: relative; 
    cursor: pointer;
    @include SP {
      width: 60px;
    }
    .line{
      width: 44px;
      position: absolute;
      top: calc(50% - 10px);
      left: 50%;
      @include SP {
        width: 34px;
        top: calc(50% - 8px);
      }
      @include transform_c(translate(-50%,-50%));
      @include add_prefix(transition, 0.3s ease all);
      i {
        height: 2px;
        line-height: 0;
        background:#fff;
        display: block;
        @include transition_c(all 0.3s ease-out);
        &:first-child {
          margin-bottom: 9px;
          @include SP {
            margin-bottom: 6px;
          }
        }
        &:last-child {
          margin-top: 9px;
          width: 20px;
          @include SP {
            width: 12px;
            margin-top: 6px;
          }
        }
      }
    }
    span {
      position: absolute;
      bottom: 13px;
      @include font-size(13);
      font-weight: 500;
      color: #fff;
      left: 50%;
      @include transform_c(translateX(-50%));
      @include eb-garamond;
      white-space: nowrap;
      letter-spacing: 0.06em;
      @include SP {
        @include font-size(10);
        bottom: 9px;
      }
    }
    @include HOVER {
      &:hover{
        .line{
          i {
            &:last-child {
               width: 44px;
            }
          }  
        }  
      }
    }
  }
  &__logo{
    width: calc(100% - 271px);
    background: #000;
    border-left: 1px solid #E0E0E0;
    position: relative;
    @include SP {
      width: calc(100% - 181px);
    }
    h1 {
      position: absolute;
      top: 50%;
      left: calc(50% + 45px);
      @include transform_c(translate(-50%,-50%));
      width: calc(100% - 90px);
      text-align: center;
      .logo-black {
        display: none;
      }
      @include SP {
        top: calc(50% - 3px);
        left: 50%;
        width: 100%;
        img{
          width: 110px;
          height: 19.36px;
        }
      }
    }
  }
  &__btns{
     position: relative;
     width: 90px;
     height: 100%;
     @include SP {
      width: 60px;
     }
     span {
        display: block;
        text-align: center;
        white-space: nowrap;
        @include noto-serif;
        @include font-size(12);
        position: absolute;
        bottom: 15px;
        left: 50%;
        @include transform_c(translateX(-50%));
        padding-top: 35px;
        @include SP {
          bottom: 11px;
          @include font-size(10);
          padding-top: 20px;
        }
     }
  }
  &__location{
     background: #D9D9D9;
     .locaction {
        background: url(../img/common/header/ico_location.svg) center top no-repeat;
        background-size: 20px 28px;
        @include SP {
          background-size: 13.76px 18.92px;
        }
     }
  }
  &__map{
     background: #4E7A91;
     cursor: pointer;
      .map {
        background: url(../img/common/header/ico_map.svg) center top no-repeat;
        background-size: 24px 24px;
        color: #fff;
        @include SP {
          background-size: 16px 16px;
        }
     }
  }
  &__menu{
    background: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    z-index: 50;
    @include transition_c(all 0.5s ease-out);
    .menu {
      padding-bottom: 90px;
      @include SP {
        padding-bottom: 30px;
      }
      &__logo{
        height: 90px;
        text-align: center;
        position: relative;
        @include SP {
          height: 60px;
        }
        a{
          top: 50%;
          left: 50%;
          @include transform_c(translate(-50%,-50%));
          position: absolute;
          @include SP {
            top: calc(50% - 3px);
            img{
              width: 110px;
              height: 19.36px;
            }
          }
        }
      }
      &__gnavi{
        @include flexbox;
        @include flex-wrap;
        @include justify-content(space-between);
        margin: 70px auto 88px;
        max-width: 870px;
        letter-spacing: 0.08em;
        @include SP {
          margin: 15px auto 15px;
          max-width: calc(100% - 40px);
        }
        li{
          width: 50%;
          margin: 20px 0;
          @include SP {
            margin: 9px 0;
            &:nth-child(odd){
              width: 58%;
            }
            &:nth-child(even){
              width: 42%;
            }
          }
          a{
            @include font-size(18);
            padding-left: 20px;
            position: relative;
            display: inline-block;
            @include SP {
              @include font-size(14);
            }
            &:before {
              @include absolute(6px,6px,calc(50% + 1px),'','',0);
              transition: all 0.6s;
              border: solid #101010;
              border-width: 0 1px 1px 0;
              @include transform_c(rotate(-45deg) translateY(-50%)); 
            }
          }
          a[rel="noopener"]{
              padding-right: 25px;
              background: url(../img/common/ico_blank.svg) no-repeat right center;
              background-size: 12px 12px;
          }
        }
      }
      &__gnaviimg{
        @include flexbox;
        @include flex-wrap;
        @include justify-content(space-between);
        @include font-size(18);
        margin: 0 auto 70px;
        @include SP {
          margin-bottom: 25px;
        }
        &:not(.gnaviimgsm) {
          max-width: $widthcmsm;
          @include max-screen($widthcmsm) {
              max-width: calc(100% - 15px);
          }
        }
        li{
          width: calc(33.3333333% - 12px);
          margin-bottom: 15px;
          @include SP {
            width: calc(33.3333333% - 5px);
            margin-bottom: 8px;
          }
          a{
            display: block;
            position: relative;
            span{
              position: absolute;
              top: 50%;
              left: 50%;
              @include transform_c(translate(-50%,-50%));
              color: #fff;
              text-align: center;
              line-height: 1.5;
              @incluimg/common/header/menu/img_mn17.jpgde PC {
                white-space: nowrap;
              }
              @include SP {
                @include font-size(12);
                width:calc(100% - 10px);
              } 
            }
            @include SP {
              height: 58px;
              overflow: hidden;
              img {
                height: auto;
                width: 100%;
                max-width: none;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform_c(translate(-50%,-50%));
              }
            }
          }
        }
        &.gnaviimgsm, &.gnaviimgsm3col{
          max-width: 880px;
          margin: auto;
          @include font-size(17);
          white-space: nowrap;
          @include SP {
            max-width:calc(100% - 21px);
          }
          li{
            img{
              border: #C4C4C4 solid 1px;
            }
            span{
              @include SP {
                line-height: 1.2;
              }
              small{
                display: block;
                margin-top: 3px;
                @include font-size(11);
                @include SP {
                  @include font-size(8);
                }
              }
            }
            @include SP {
              a{
                height: 52px;
              }
            }
          }
        }  
        &.gnaviimgsm{
          li{
            width: calc(50% - 7px);
            margin-bottom: 10px;
            @include SP {
              margin-bottom: 8px;
              width: calc(50% - 4px)
            }
            &:nth-child(1){
              a{
                span{
                  top: calc(50% - 0px);
                  color: #1c1c1c;
                  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
                  @include transform_c(translate(0%,-50%));
                  left: calc(50% - 45px);
                  @include noto-serif;
                  letter-spacing: 0.08em;
                  @include SP {
                    left: calc(50% + 15px);
                    text-align: left;
                    @include font-size(8);
                  }
                }
              }
            }
            &:nth-child(2){
              a{
                span{
                  top: calc(50% - 8px);
                  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
                  @include noto-serif;
                  letter-spacing: 0.08em;
                }
              }
            }
          }
        }
        &.gnaviimgsm3col{
          li{
            width: calc(33.333333% - 7px);
            @include PC {
              margin-bottom: 10px;
            }
            &:nth-child(3){
              a {
                span{
                  @include font-size(9);
                }
              }
            }  
          }
        }
      }
      &__btn{
        max-width: 878px;
        margin: auto;
        border: #C4C4C4 solid 1px;
        position: relative;
        margin-bottom: 10px;
        @include SP {
          max-width:calc(100% - 21px);
        }
        a{
          display: block;
          height: 80px;
          position: relative;
          @include SP {
            height: 52px;
          }
          &:after {
            @include absolute(8px,8px,50%,25px,'','');
            display: inline-block;
            transition: all 0.6s;
            border: solid #707070;
            border-width: 0 1px 1px 0;
            @include transform_c(rotate(-45deg) translateY(-50%)); 
            @include SP {
              width: 7px;
              height: 7px;
            }
          }
          img{
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform_c(translate(-50%,-50%));
            width: 262px;
            @include SP {
              max-width: 214px;
            }
          }
        }
      }
      &__text{
        @include noto-serif;
        @include font-size(22);
        text-align: center;
        margin-bottom: 35px;
        letter-spacing: 2px;
        @include SP {
          @include font-size(16);
          margin-bottom: 15px;
        }
      }
    }
  }
} 
.btnclose {
  display: block;
  width: 100%;
  max-width: 52px;
  @include eb-garamond;
  @include font-size(13);
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.06em;
  line-height: 18px;
  padding-top: 26px;
  margin: 80px auto 0;
  position: relative;
  @include SP {
    max-width: fit-content;
    font-size: 14px;
    padding-top: 0;
    padding-left: 12px;
    margin-top: 40px;
    letter-spacing: 0.03em;
    line-height: 10px;
    @include noto-sans;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: #101010;
    width: 44px;
    height: 1px;
    top: 10px;
    left: 50%;
    @include SP {
      width: 12px;
      top: 5px;
      left: 0;
    }
  }
  &::before {
    @include transform_c(translateX(-50%) rotate(24deg));
    @include SP {
       @include transform_c(translateX(-50%) rotate(45deg));
    }
  }
  &::after {
    @include transform_c(translateX(-50%) rotate(-24deg));
    @include SP {
      @include transform_c(translateX(-50%) rotate(-45deg));
    }
  }
}
.layerOn {
  overflow: hidden;
  .header__menu {
    opacity: 1;
    overflow: auto;
    pointer-events: auto;
  }
  .header__hamburger {
    background: #fff;
    .line{
      i {
        background: #101010;
        position: relative;
        width: 44px;
        @include SP {
          width: 34px;
        }
        &:first-child {
          top: 13px;
          @include transform_c(rotate(26deg));
          @include SP {
            top: 6px;
          }
        }
        &:last-child {
          @include transform_c(rotate(-26deg));
          top: 1px;
          @include SP {
            top: -2px;
          }
        }
        &:nth-child(2){
          display: none;
        }  
      }
    }
    span {
      color: #101010;
    }  
  }
}
.mapgroup {
  background: rgba(255, 255, 255, 0.96);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 90;
  @include transition_c(all 0.5s ease-out);
  padding: 115px 0 70px;
  @include SP {
    padding: 40px 0 50px;
  }
  &__close{
    width: 90px;
    height: 90px;
    top: 0;
    right: 22px;
    text-align: center;
    position: fixed; 
    cursor: pointer;
    @include SP {
      width: 40px;
      height: 40px;
      right: 2px;
    }
    .line{
      width: 44px;
      position: absolute;
      top: calc(50% - 10px);
      left: 50%;
      @include SP {
        width: 22px;
        top: calc(50% - 8px);
      }
      @include transform_c(translate(-50%,-50%));
      @include add_prefix(transition, 0.3s ease all);
      i {
        height: 2px;
        line-height: 0;
        background: #101010;
        display: block;
        position: relative;
        @include transition_c(all 0.3s ease-out);
        &:first-child {
          margin-bottom: 9px;
          top: 13px;
          @include transform_c(rotate(26deg));
          @include SP {
            top: 6px;
            margin-bottom: 6px;
          }
        }
        &:last-child {
          margin-top: 9px;
          @include transform_c(rotate(-26deg));
          top: 2px;
          @include SP {
            top: -2px;
            margin-top: 6px;
          }
        }
      }
    }
    span {
      position: absolute;
      bottom: 13px;
      @include font-size(13);
      font-weight: 500;
      color: #101010;
      left: 50%;
      @include transform_c(translateX(-50%));
      @include eb-garamond;
      white-space: nowrap;
      letter-spacing: 0.06em;
      @include SP {
        @include font-size(7);
        bottom: 9px;
      }
    }
  }
  &__inner{
    max-width: $widthcmsm;
    margin: auto;
    @include max-screen($widthcmsm) {
        max-width: calc(100% - 15px);
        @include SP {
          max-width: 100%;
        }
    }
  }
  &__list{
    margin: 65px 0 0;
    @include flexbox;
    @include flex-wrap;
    @include justify-content(space-between);
    list-style: none;
    counter-reset: my-awesome-counter;
    @include SP {
      margin: 20px auto 0;
      max-width: calc(100% - 15px);
    }
    li {
      counter-increment: my-awesome-counter;
      width: 32%;
      position: relative;
      margin-bottom: 25px;
      padding-left: 43px;
      height: 45px;
      line-height: 1.4;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      @include SP {
        @include font-size(9);
        line-height: 1.6;
        padding-left: 22px;
        margin-bottom: 15px;
        height: auto;
        letter-spacing: 0.05em;
      }
      span {
        width: 30px;
        height: 30px;
        background: #1c1c1c;
        color: #fff;
        display: block;
        content: counter(my-awesome-counter);
        @include font-size(17);
        position: absolute;
        line-height: 1.7;
        text-align: center;
        left: 0;
        top: -3px;
        @include border-radius(50%);
        @include noto-serif;
        @include SP {
          width: 16px;
          height: 16px;
          @include font-size(12);
          line-height: 1.3;
          letter-spacing: -1px;
        }
      }
    }
    &:after{
      width: 32%;
      content: "";
    }
  }  
}
.layerMap {
  overflow: hidden;
  .mapgroup{
    opacity: 1;
    overflow: auto;
    pointer-events: auto;
     overflow-y: scroll;
    .btnclose{
      margin-top: 58px;
      @include SP {
        margin-top: 15px;
      }
    }
  }
}
.top {
  .header__hamburger {
    background: none;
    i{
      background: #101010;
    }
    span{
      color: #101010
    }
  }
  .header__logo {
    background: none;
    border-left: none;
    h1{
      display: none;
      @include PC {
        .logo-white {
          display: none;
        }
      }
      @include SP {
        display: block;
        .logo-white {
          display: none;
        }  
        .logo-black {
          display: inline;
        }
      }
    }
  }
  .headerwhite {
    background: #fff;
    h1{
      display: block;
      .logo-black {
        display: inline;
      }
    }
  }
}